(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("chatVideoService", chatVideoService);

    function chatVideoService($rootScope,
                              $filter,
                              $timeout,
                              $log,
                              $sanitize,
                              $localStorage,
                              $mdDialog,
                              $translate,
                              $q,
                              coreData,
                              coreDataSettings,
                              userDataService,
                              signalrHubManager,
                              simpleRtcConnectionFactory,
                              authStorage,
                              errorHandling,
                              browserSupport,
                              toaster) {

        // Data
        const vm = this;
        let didDisconnect = false;
        vm.supportsRtc = false;
        vm.mailHub = null;
        vm.targetEmail = '';
        vm.callStart = undefined;

        // Functions
        vm.callUser = callUser;
        vm.respondToCall = respondToCall;
        vm.disconnectCall = disconnectCall;
        vm.joinCall = joinCall;
        vm.disconnectRtc = disconnectRtc;
        vm.setMuted = setMuted;
        vm.setVideoOn = setVideoOn;

        $rootScope.$on('signalR.chatVideo.rtcEmitReturn', onRtcEmitReturn);
        $rootScope.$on('signalRHubManagerConnected', connectSignalR);
        $rootScope.$on('signalRHubManagerReconnected', connectSignalR);

        activate();

        function activate() {
            connectSignalR();

            createVideoConnection();
        }

        function connectSignalR() {
            signalrHubManager.init()
                .then(function (connection) {
                    vm.mailHub = connection.mailHub;
                    vm.supportsRtc = $rootScope.browserCaps.webRtcSupport
                }, function () {
                });
        }

        function callUser(email, audioOnly) {
            vm.audioOnly = audioOnly;
            vm.targetEmail = email;
            const defer = $q.defer();
            vm.mailHub.invoke("callUser", email, userDataService.user.emailAddress, audioOnly)
                .then(function (err) {
                    if (err.error) {
                        const textContent = err.error == "alreadyInCall" ? "CHAT_ALREADY_IN_CALL_TEXT" :
                            "CHAT_TARGET_ALREADY_IN_CALL_TEXT";
                        const confirm = $mdDialog.confirmDeletion({
                            title: $translate.instant('CHAT_ALREADY_IN_CALL_TITLE'),
                            textContent: $translate.instant(textContent),
                            ok: $translate.instant('OK'),
                            noWarn: true,
                            hideCancel: true
                        });
                        $mdDialog.show(confirm);
                    }
                    defer.resolve(err);
                });
            return defer.promise;
        }

        function respondToCall(email, sourceEmail, accepted) {
            if (accepted) {
                vm.targetEmail = email;
            }

            const defer = $q.defer();
            vm.mailHub.invoke("respondToCall", email, sourceEmail, accepted)
                .then(function (err) {
                    if (err.error && accepted) {
                        const confirm = $mdDialog.confirmDeletion({
                            title: $translate.instant('CHAT_ALREADY_IN_CALL_TITLE'),
                            textContent: $translate.instant(err.error == "alreadyInCall" ? "CHAT_ALREADY_IN_CALL_TEXT" :
                                'CHAT_CALL_UNAVAILABLE_TEXT'),
                            ok: $translate.instant('OK'),
                            noWarn: true,
                            hideCancel: true
                        });
                        $mdDialog.show(confirm);
                    }
                    defer.resolve(err);
                });
            return defer.promise;
        }

        function disconnectCall(sourceEmail, callTimedOut) {
            vm.mailHub.invoke("disconnectCall", sourceEmail, callTimedOut);
        }


        function callListener(name, data) {
            if (vm.videoConnection.listeners[name]) {
                vm.videoConnection.listeners[name].forEach(function (element) {
                    if (data) {
                        element(undefined, data);
                    } else {
                        element(undefined);
                    }
                });
            }
        }

        function rtcConnect() {
            callListener('connect');
        }

        function onRtcEmitReturn(ev, data) {
            switch (data.name) {
                case 'join':
                    if (data.attendeeId == userDataService.user.emailAddress) {
                        callListener('join', data.args);
                    }
                    break;
                case 'message':
                    if (data.args.to === userDataService.user.emailAddress) {
                        if (vm.videoConnection.listeners['message']) {
                            vm.videoConnection.listeners['message'].forEach(function (element) {
                                data.args.from = data.attendeeId;
                                element(data.args);
                            });
                        }
                    }
                    break;
                case 'remove':
                    if (data.attendeeId != userDataService.user.emailAddress) {
                        if (vm.videoConnection.listeners['remove']) {
                            vm.videoConnection.listeners['remove'].forEach(function (element) {
                                element({
                                    id: data.attendeeId,
                                    type: data.args
                                });
                            });
                        }
                    }
                    break;
                default:
                    if (data.attendeeId != userDataService.user.emailAddress) {
                        if (vm.videoConnection.listeners[data.name]) {
                            vm.videoConnection.listeners[data.name].forEach(function (element) {
                                element(data.args);
                            });
                        }
                    }
                    break;
            }
        }

        function createVideoConnection() {
            vm.videoConnection = {
                listeners: {},

                on: function (ev, fn) {
                    if (this.listeners[ev] == undefined) {
                        this.listeners[ev] = [fn];
                    } else {
                        this.listeners[ev].push(fn);
                    }
                },
                emit: function () {
                    const args = arguments;
                    switch (arguments[0]) {
                        case 'join':
                            const keys = Object.keys(args);
                            keys.forEach(function (arg) {
                                if (typeof args[arg] == 'function') {
                                    const data = {
                                        clients: {}
                                    };
                                    data.clients[vm.targetEmail] = {
                                        video: true, audio: false, screen: false
                                    };
                                    args[arg](undefined, data);
                                }
                            });
                            break;

                        case 'unshareScreen':
                            vm.mailHub.invoke("rtcEmit", args);
                            break;

                        case 'shareScreen':
                            vm.mailHub.invoke("rtcEmit", args);
                            break;

                        case 'message':
                            vm.mailHub.invoke("rtcEmit", args);
                            break;

                        case 'remove':
                            vm.mailHub.invoke("rtcDisconnect", args[1].type);
                            break;

                        default:
                            vm.mailHub.invoke("rtcEmit", args);
                            break;
                    }

                },
                getSessionid: function () {
                    return userDataService.user.emailAddress;
                },
                disconnect: function () {
                    this.listeners = {};
                    vm.mailHub.invoke("rtcDisconnect", "");
                }
            };
        }

        function joinCall(doJoinRoom) {
            
            didDisconnect = false;
            vm.callStart = moment();
            navigator.mediaDevices.getUserMedia({audio: true, video: true})
                .then(async function (stream) {
                    vm.localAVStream = stream;
                    const localVideo = $('#localVideo video');
                    if (localVideo.length) {
                        localVideo[0].srcObject = stream;
                        localVideo[0].play();
                    }
                    const onStreamReceived = (kind, senderStream) => {
                        switch (kind) {
                            case 'video':
                                const remoteVideo = $('#remoteVideo video');
                                if (remoteVideo.length) {
                                    remoteVideo[0].srcObject = senderStream;
                                    remoteVideo[0].play();
                                }                                
                                break;
                            case 'audio':
                                const remoteAudio = $('#remoteVideo audio');
                                if (remoteAudio.length) {
                                    remoteAudio[0].srcObject = senderStream;
                                    remoteAudio[0].play();
                                }
                                break;
                        }
                    }
                    const sendRtcNegotiateMessage = async (message) => {
                        vm.mailHub.invoke("RtcSendMessage", message);
                    }
                    vm.videoPeer = await simpleRtcConnectionFactory.createConnection(
                        sendRtcNegotiateMessage, 
                        onStreamReceived, 
                        doJoinRoom, {
                            iceServers: [{urls: "stun:stun.l.google.com:19302"}]
                        });
                    stream.getTracks().forEach(track => {
                            
                        vm.videoPeer.rtcConn.addTrack(track, stream);
                    });
                    
                    if (doJoinRoom) {
                        await vm.videoPeer.initiateNegotiation();
                    } else {
                        vm.respondToCall(vm.receivingCallData.sourceEmail, userDataService.user.emailAddress, true)
                            .then(function (err) {
                                if (err.error) {
                                    $rootScope.$broadcast('signalR.chatVideo.disconnectCall', {callTimedOut: true});
                                    disconnectCall(userDataService.user.emailAddress);
                                }
                            });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    $rootScope.$broadcast('signalR.chatVideo.disconnectCall');
                    disconnectCall(userDataService.user.emailAddress, true);
                    const confirm = $mdDialog.confirmDeletion({
                        title: $translate.instant('UNABLE_TO_ACCESS_CAMERA'),
                        textContent: $translate.instant("TEAM_WORKSPACE_NEED_DEVICE"),
                        ok: $translate.instant('OK'),
                        noWarn: true,
                        hideCancel: true
                    });
                    $mdDialog.show(confirm);
                });
        }

        function disconnectRtc() {
            didDisconnect = true;
            if (vm.localAVStream) {
                vm.localAVStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            if (vm.videoPeer) {
                const localVideo = $('#localVideo video');
                if (localVideo.length && localVideo[0].srcObject) {
                    localVideo[0].srcObject = null;
                }
            
                if (vm.videoPeer.rtcConn){
                    vm.videoPeer.rtcConn.close();
                }
            }
        }

        function setMuted(muted) {
            if (vm.localAVStream) {
                vm.localAVStream.getAudioTracks().forEach(track => {
                    track.enabled = !muted;
                });
            }
        }

        function setVideoOn(videoOn) {
            if (vm.localAVStream) {
                vm.localAVStream.getVideoTracks().forEach(track => {
                    track.enabled = videoOn;
                });
            }
        }
    }

})();