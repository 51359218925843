(function () {
    "use strict";
    angular
        .module("smartermail")
        .controller("sysAdminDomainConfigController", sysAdminDomainConfigController);

    function sysAdminDomainConfigController($rootScope, $scope, $state, $http, $q, $translate, $mdDialog, $timeout, $filter,
        claimsService, coreDataLicensing, coreLicensing, errorHandling, successHandling, toaster) {

        // Variables
        var vm = this;
        vm.theDomainName = $state.params.id;
        vm.idnName = $state.params.idn;
        $state.current.data.pageTitle = vm.idnName || vm.theDomainName;
        vm.archiveRuleDisabled = true;
        vm.isInitialized = false;
        vm.globalMail = {};
        vm.settings = {};
        vm.domainInfo = {};
        vm.IPV4Options = [];
        vm.IPV6Options = [];
        vm.isIpFailover = false;
        vm.disableIpv6 = false;
        vm.diskSpaceInput = 0;
        vm.maxMessageSizeInput = 0;
        vm.maxAttachedFileSizeInput = 0;
        vm.currentUserOption = {};
        vm.Licensing = [];
        vm.userSearch = "";
        vm.canManage = false;
        vm.canChangeShowPassword = claimsService.canViewPasswords();
        vm.enableGreylisting = true;
        vm.catchAllEnabled = false;
        vm.outgoingGateways = [];
        vm.IsOnlyOfficeSecretKeyHidden = true;
        vm.twoFactorFlags =
        {
            Enabled: 1,
            Forced: 2,
            Locked: 4
        };
        vm.twoFactorSettingOptions = [
            { value: 1, translation: "ENABLED" },
            { value: 2, translation: "FORCED" },
            { value: 6, translation: "FORCE_LOCKED" }
        ];
        vm.ipv4SetBySystem = false;
        vm.ipv6SetBySystem = false;
        vm.allowArchivingRules = coreLicensing.hasEnterpriseFunctionality;
        vm.marketplaceItems = {
            onlyOffice: { enabled: false },
            collabora: { enabled: false }
        };


        // Functions
        vm.save = save;
        vm.deleteThis = deleteThis;
        vm.showHSTSPopup = showHSTSPopup;
        vm.onShowOnlyOfficeSecretClicked = onShowOnlyOfficeSecretClicked;
        //vm.manage = manage;
        vm.openChangeDomainName = openChangeDomainName;
        vm.openChangeDomainPath = openChangeDomainPath;
        vm.reloadDomain = reloadDomain;
        vm.detachDomain = detachDomain;
        vm.clearSearchTerm = clearSearchTerm;
        vm.cancel = cancel;
        vm.reindexDomainArchive = reindexDomainArchive;
        vm.editAchiveRule = editAchiveRule;
        vm.disableArchivingRule = disableArchivingRule;
        // Modals
        vm.openAdvancedXMLBody = openAdvancedXMLBody;
        vm.openAutoDiscoverModal = openAutoDiscoverModal;
        vm.toggleTwoFactor = function () {
            if (!vm.isTwoFactorForced) {
                vm.isTwoFactorLocked = false;
            }
        }
        $scope.$on("licensingChanged", onLicensingChanged);

        activate();

        ////////////////////////////////

        function activate() {
            $rootScope.spinner.show();
            var promises = [
                $http.get("~/api/v1/settings/sysadmin/global-mail").then(onGlobalMailSettingsLoaded, function () { }),
                $http.get(`~/api/v1/settings/sysadmin/domain-settings/${vm.theDomainName}/`).then(onDomainSettingsLoaded, onDomainSettingsLoadError),
                $http.get("~/api/v1/settings/sysadmin/user-login").then(onUserLoginLoaded, function () { }),
                $http.get("~/api/v1/settings/sysadmin/gateways").then(onGatewaysLoaded, function () { }),
                $http.get(`~/api/v1/settings/sysadmin/ad-settings/${vm.theDomainName}/`).then(onAutoDiscoverSettingsLoaded, function () { }),
            ];

            $q
                .all(promises)
                .then(onSuccess, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onSuccess() {
                vm.pageForm.$setSubmitted();
                vm.isInitialized = true;
                vm.allowArchivingRules = coreLicensing.hasEnterpriseFunctionality;
                if (vm.allowArchivingRules) {
                    $http.get("~/api/v1/settings/sysadmin/domain-archive-rules").then(onArchiveRulesLoaded, function () { });
                }
            }

            function onGatewaysLoaded(result) {
                vm.outgoingGateways = result.data.gatewayConfigs;
            }
            function onArchiveRulesLoaded(result) {
                var archiveRules = result.data.rules || [];
                vm.domainArchiveRule = archiveRules.find(rule => rule.domain.toLowerCase() === vm.theDomainName.toLowerCase());
                var hasExistingRule = vm.domainArchiveRule !== undefined;
                vm.archiveRuleDisabled = !hasExistingRule || vm.domainArchiveRule.disabled;
                vm.archiveRule = {
                    // if outgoing and incoming are both false then it was essentially disabled so we need use default value of All
                    type: vm.archiveRuleDisabled || (vm.domainArchiveRule.outgoing === vm.domainArchiveRule.incoming)
                        ? "1" :
                        vm.domainArchiveRule.incoming ? "2" : "3",
                    archivePath: !vm.archiveRuleDisabled ? vm.domainArchiveRule.archivePath : `${vm.globalMail.pathSettings.archivePath}${vm.theDomainName}`,
                    yearsToKeep: !vm.archiveRuleDisabled && typeof vm.domainArchiveRule.monthsToKeep === "number"
                        ? vm.domainArchiveRule.monthsToKeep > 0 ? Math.ceil(vm.domainArchiveRule.monthsToKeep / 12) : 0
                        : 5,
                    domain: vm.theDomainName,
                    isNew: !hasExistingRule,
                    disabled: vm.archiveRuleDisabled
                }
            }
          

            function onGlobalMailSettingsLoaded(result) {
                vm.globalMail = result.data.globalMailSettings;
                return $http.get("~/api/v1/settings/sysadmin/ips").then(onIpsLoaded);
            }

            function onIpsLoaded(result) {
                vm.IPV4Options = [{ value: "", translation: "AUTOMATIC" }];
                vm.IPV6Options = [{ value: "", translation: "AUTOMATIC" }];

                var failoverIp = vm.globalMail.failoverIPAddress;
                if (failoverIp && failoverIp !== "NONE") {
                    vm.isIpFailover = true;
                    vm.disableIpv6 = true;

                    vm.IPV4Options = [{ value: "", translation: "HANDLED_VIA_FAILOVER" }];
                    //Don't need to do IPv6 since it is hidden in this scenario.
                }
                if (vm.globalMail.deliverySettings.defaultOutboundDeliveryBindingIPv6.toLowerCase() === "disable") {
                    vm.disableIpv6 = true;
                }

                var ipv4 = $.grep(result.data.ips,
                    function (ip) { return ip.addressFamily === 2 && ip.address !== "127.0.0.1" });
                ipv4 = $.map(ipv4, function (ip) { return { value: ip.address, translation: ip.address } });
                vm.IPV4Options = vm.IPV4Options.concat(ipv4);

                var ipv6 = $.grep(result.data.ips,
                    function (ip) { return ip.addressFamily === 23 && ip.address !== "::1" });
                ipv6 = $.map(ipv6, function (ip) { return { value: ip.address, translation: ip.address } });
                vm.IPV6Options = vm.IPV6Options.concat(ipv6);

                vm.ipv4SetBySystem = !vm.isIpFailover && vm.globalMail.deliverySettings.outboundDeliveryBinding != 2;
                vm.ipv6SetBySystem = !vm.disableIpv6 && vm.globalMail.deliverySettings.outboundDeliveryBindingIPv6 != 2;

                $timeout(checkSettingsLoaded);

                function checkSettingsLoaded() {
                    if (!vm.settings || vm.settings === {}) {
                        $timeout(checkSettingsLoaded);
                        return;
                    }
                    if (vm.settings.outgoingIP == null || !_.some(vm.IPV4Options, function (x) { return (x.value || "") === vm.settings.outgoingIP; }))
                        vm.settings.outgoingIP = "";
                    if (vm.settings.outgoingIPv6 == null || !_.some(vm.IPV6Options, function (x) { return (x.value || "") === vm.settings.outgoingIPv6; }))
                        vm.settings.outgoingIPv6 = "";
                }
            }

            function onDomainSettingsLoadError() {
                $state.go("index.sysadmin-manage.dashboard");
            }

            function onUserLoginLoaded(result) {
                vm.canManage = result.data.systemLogin.allowImpersonation;
            }

            function onAutoDiscoverSettingsLoaded(result) {
                vm.autoDiscoverSettings = result.data.settings;
            }

            $timeout(function () {
                $("#userSearch").on("keydown", function (ev) { ev.stopPropagation(); });
            });
        }
        function onShowOnlyOfficeSecretClicked() {
            vm.IsOnlyOfficeSecretKeyHidden = !vm.IsOnlyOfficeSecretKeyHidden;
        }

        function onDomainSettingsLoaded(result) {
            vm.settings = angular.copy(result.data.domainSettings);
            vm.diskSpaceInput = parseInt(vm.settings.maxSize / 1024 / 1024, 10);
            vm.maxMessageSizeInput = parseInt(vm.settings.maxMessageSize / 1024, 10);
            vm.maxAttachedFileSizeInput = parseInt(vm.settings.maxAttachedFileSize / 1024, 10);
            vm.enableGreylisting = !vm.settings.bypassGreyListing;
            vm.mailingListMaxMessageSize = parseInt(vm.settings.mailingListMaxMessageSize / 1024, 10);
            vm.hardcodedMessageSize = parseInt(vm.settings.hardcodedMessageSize / 1024, 10);
            vm.hardcodedAttachmentSize = parseInt(vm.settings.hardcodedAttachmentSize / 1024, 10)
            vm.catchAllEnabled = vm.settings.caRestrictionLevel !== 2;
            vm.isTwoFactorForced = (vm.settings.twoFactorSettings.setting & vm.twoFactorFlags.Forced) === vm.twoFactorFlags.Forced;

            vm.isTwoFactorLocked = (vm.settings.twoFactorSettings.setting & vm.twoFactorFlags.Locked) === vm.twoFactorFlags.Locked;

            vm.currentUserOption = vm.settings.mainDomainAdmin;

            return coreDataLicensing
                .init()
                .then(onLicensingLoaded, errorHandling.report);

            function onLicensingLoaded() {
                vm.Licensing = angular.copy(coreDataLicensing.Data.Licensing);
                if (!vm.Licensing.enterpriseFunctionality) {
                    vm.settings.activeDirectoryIntegration = false;
                    vm.settings.enableRemoteWipe = false;
                    vm.settings.enableActiveSyncAccountManagement = false;
                    vm.settings.enableXmpp = false;
                    vm.settings.webConferencing = false;
                    vm.settings.emClientEnabled = false;
                } else if (!vm.Licensing.isActivated) {
                    vm.settings.emClientEnabled = false;
                }

            }
        }

        function onLicensingChanged() {
            coreDataLicensing
                .init()
                .then(activate, errorHandling.report);
        }

        async function showHSTSPopup(newVal) {
            if (newVal) {
                try {
                    await $mdDialog.show({
                        locals: {},
                        controller: "hstsWarningController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/sysadmin/manage/domains/modals/hsts-warning.dlg.html",
                        clickOutsideToClose: false
                    });
                } catch (err) {
                    vm.settings.hstsEnabled = false;
                }
            }
        }

        function cancel() {
            $state.go("index.sysadmin-manage.dashboard");
        }

        function save(form, ev) {
            if (form.$invalid) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            //let twoFactorSetting = vm.isTwoFactorLocked
            //	? vm.twoFactorFlags.Forced | vm.twoFactorFlags.Locked
            //	: vm.isTwoFactorForced
            //	? vm.twoFactorFlags.Forced
            //	: vm.twoFactorFlags.Enabled;
            var params = {
                domainSettings: {
                    isEnabled: vm.settings.isEnabled,
                    hostname: vm.settings.hostname,
                    domainLocation: vm.settings.domainLocation,
                    domainLocationAddress: vm.settings.domainLocationAddress,
                    deliverLocallyForExternalDomain: vm.settings.deliverLocallyForExternalDomain,

                    maxDomainAliases: vm.settings.maxDomainAliases || 0,
                    maxUsers: vm.settings.maxUsers,
                    maxAliases: vm.settings.maxAliases,
                    maxLists: vm.settings.maxLists,
                    mailingListMaxMessageSize: vm.mailingListMaxMessageSize * 1024,
                    maxMessageSize: vm.maxMessageSizeInput * 1024,
                    maxAttachedFileSize: vm.maxAttachedFileSizeInput * 1024,
                    maxRecipients: vm.settings.maxRecipients,
                    maxActiveSyncAccounts: vm.settings.maxActiveSyncAccounts,
                    maxSize: vm.diskSpaceInput * 1024 * 1024,
                    maxMapiEwsAccounts: vm.settings.maxMapiEwsAccounts,

                    messagePriority: vm.settings.messagePriority,

                    enableMapiEwsAccountManagement: vm.settings.enableMapiEwsAccountManagement,
                    outgoingIP: vm.settings.outgoingIP,
                    outgoingIPv6: vm.settings.outgoingIPv6,
                    domainUrl: vm.settings.domainUrl,
                    overrideRedirect: vm.settings.overrideRedirect,
                    spamRelayOption: vm.settings.spamRelayOption,
                    spamForwardOption: vm.settings.spamForwardOption,
                    enableTlsIfAvailable: vm.settings.enableTlsIfAvailable,
                    srsEnabled: vm.settings.srsEnabled,
                    requireSmtpAuthentication: vm.settings.requireSmtpAuthentication,
                    bypassGreyListing: !vm.enableGreylisting,
                    enableSenderVerification: vm.settings.enableSenderVerification,
                    excludeIPFromReceivedLine: vm.settings.excludeIPFromReceivedLine,
                    postmasterMailbox: vm.settings.postmasterMailbox,
                    sslRequired: vm.settings.sslRequired,

                    sharedGlobalAddressList: vm.settings.sharedGlobalAddressList,

                    throttleSettings: {
                        messagesPerHour: vm.settings.throttleSettings.messagesPerHour,
                        messagesAction: vm.settings.throttleSettings.messagesAction,
                        bandwidthPerHour: vm.settings.throttleSettings.bandwidthPerHour,
                        bandwidthAction: vm.settings.throttleSettings.bandwidthAction,
                        bouncesPerHour: vm.settings.throttleSettings.bouncesPerHour,
                        bouncesAction: vm.settings.throttleSettings.bouncesAction
                    },

                    activeDirectoryIntegration: vm.settings.activeDirectoryIntegration,
                    enableRemoteWipe: vm.settings.enableRemoteWipe,
                    enableActiveSyncAccountManagement: vm.settings.enableActiveSyncAccountManagement,
                    caRestrictionLevel: (vm.catchAllEnabled ? 1 : 2),
                    enableDisposableAddresses: vm.settings.enableDisposableAddresses,
                    showDomainAliasMenu: vm.settings.showDomainAliasMenu,
                    allowUserSizeChanging: vm.settings.allowUserSizeChanging,
                    enableDomainHistory: vm.settings.enableDomainHistory,
                    enableGoogleDriveIntegration: vm.settings.enableGoogleDriveIntegration,
                    enableXmpp: vm.settings.enableXmpp,
                    enableFileStorage: vm.settings.enableFileStorage,
                    onlyOffice: {
                        enabled: vm.settings.onlyOffice.enabled,
                        serverUrl: vm.settings.onlyOffice.serverUrl,
                        secretKey: vm.settings.onlyOffice.secretKey,
                        maxConnections: vm.settings.onlyOffice.maxConnections,
                        maxConnectionsPerUser: vm.settings.onlyOffice.maxConnectionsPerUser,
                        },
                    wopi: {
                        enabled: vm.settings.wopi.enabled,
                        url: vm.settings.wopi.url,
                        maxConnections: vm.settings.wopi.maxConnections,
                        maxConnectionsPerUser: vm.settings.wopi.maxConnectionsPerUser,
                    },
                    twoFactorSettings: {
                        setting: vm.settings.twoFactorSettings.setting
                    },
                    customLoginDisplay: vm.settings.customLoginDisplay,
                    webConferencing: vm.settings.webConferencing,
                    showListMenu: vm.settings.showListMenu,
                    gatewayGuid: vm.settings.gatewayGuid,
                    gatewayFailover: vm.settings.gatewayFailover,
                    enableMailForwarding: vm.settings.enableMailForwarding,
                    emClientEnabled: vm.settings.emClientEnabled,
                    hstsEnabled: vm.settings.hstsEnabled,
                    enableSharing: vm.settings.enableSharing
                }
            };
            if (vm.canChangeShowPassword) {
                params.domainSettings.enableShowPasswords = vm.settings.enableShowPasswords;
            }

            $rootScope.spinner.show();
            return $http
                .post(`~/api/v1/settings/sysadmin/domain-settings/${vm.theDomainName}/`, JSON.stringify(params))
                .then(doAutoDiscoverSave, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function doAutoDiscoverSave() {
                $http
                    .post(`~/api/v1/settings/sysadmin/ad-settings/${vm.theDomainName}/`, JSON.stringify(vm.autoDiscoverSettings))
                    .then(onSaveSuccess, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }

            function onSaveSuccess(result) {
                // Check if the admin has changed
                if (vm.currentUserOption.toUpperCase() !== vm.settings.mainDomainAdmin.toUpperCase()) {
                    var params = { domain: vm.theDomainName, username: vm.currentUserOption, createAdmin: false };
                    $rootScope.spinner.show();
                    $http
                        .post("~/api/v1/settings/sysadmin/primary-domain-admin", JSON.stringify(params))
                        .then(done, errorHandling.report)
                        .finally($rootScope.spinner.hide);
                } else {
                    done();
                }
            }

            function done() {
                form.$setPristine();
            }
        }

        function deleteThis(form, ev) {
            var confirm = $mdDialog.confirmDeletion()
                .textContent($translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
                .targetEvent(ev);

            $mdDialog
                .show(confirm)
                .then(onConfirm);

            function onConfirm() {
                $rootScope.spinner.show();
                $http
                    .post(`~/api/v1/settings/sysadmin/domain-delete/${vm.theDomainName}/true`)
                    .then(onDeleteSuccess, errorHandling.report)
                    .finally($rootScope.spinner.hide);

                function onDeleteSuccess() {
                    form.$setPristine();
                    $state.go("index.sysadmin-manage.dashboard");
                }
            }
        }

        function openChangeDomainName(ev) {
            $mdDialog
                .show({
                    locals: { oldDomainName: vm.theDomainName, oldIdn: vm.idnName },
                    controller: "editDomainNameController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/manage/domains/modals/edit-domainname.dlg.html",
                    targetEvent: ev,
                    clickOutsideToClose: false
                })
                .then(onModalSuccess);

            function onModalSuccess(result) {
                $state.go("index.sysadmin-manage.dashboard");
            }
        }

        function openChangeDomainPath(ev) {
            $mdDialog
                .show({
                    locals: { oldDomainPath: vm.settings.domainPath },
                    controller: "editDomainPathController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/manage/domains/modals/edit-domain-path.dlg.html",
                    targetEvent: ev,
                    clickOutsideToClose: false
                })
                .then(onModalSuccess);

            function onModalSuccess(result) {
                var newDomainPath = result.newDomainPath;
                if (newDomainPath.toLowerCase() === vm.settings.domainPath.toLowerCase())
                    return;

                var params = JSON.stringify({
                    oldDomainPath: vm.settings.domainPath,
                    newDomainPath: newDomainPath
                });

                $http
                    .post(`~/api/v1/settings/sysadmin/domain-path/${vm.theDomainName}`, params)
                    .then(onDomainPathChanged, onDomainPathErrored);

                function onDomainPathChanged() {
                    vm.settings.domainPath = newDomainPath;
                    $state.go("index.sysadmin-manage.dashboard");
                }

                function onDomainPathErrored(failure) {
                    if (failure.data.message.startsWith("The new path must be on the same drive.")) {
                        var confirm = $mdDialog.confirmDeletion()
                            .title($translate.instant("ACTION_FAILED"))
                            .textContent($translate.instant("ERROR_PATH_ON_DIFFERENT_DRIVE"))
                            .ok($translate.instant("OK"))
                            .noWarn(true)
                            .hideCancel(true);
                        $mdDialog.show(confirm);
                    }
                    else
                        errorHandling.report(failure);
                }
            }
        }

        async function detachDomain(form, ev) {
            try {
                var confirm = $mdDialog.confirm()
                    .title($filter("translate")("DETACH_DOMAIN"))
                    .textContent($translate.instant("CONFIRMATIONS_DETACH_DOMAIN", { count: 1 }))
                    .targetEvent(ev)
                    .ok($filter("translate")("DETACH"))
                    .cancel($filter("translate")("CANCEL"));
                await $mdDialog.show(confirm);
            } catch (err) {
                // exceptions ignored, because that's just user closing the modal
                return;
            }

            let toast;
            try {
                toast = toaster.pop({
                    type: 'info',
                    title: null,
                    body: $translate.instant('DOMAIN_MANAGEMENT_DETACH_DOMAIN_RUNNING'),
                    timeout: 0,
                    bodyOutputType: 'trustedHtml'
                });

                var result = await $http.post(`~/api/v1/settings/sysadmin/detach-domain/${vm.theDomainName}`);
                successHandling.report(result);
                form.$setPristine();
                $state.go("index.sysadmin-manage.dashboard");
            }
            catch (err) {
                errorHandling.report(err);
            } finally {
                toaster.clear(toast);
            }
        }

        function reloadDomain(ev) {
            var confirm = $mdDialog.confirm()
                .title($filter("translate")("RELOAD_DOMAIN"))
                .textContent($translate.instant("CONFIRMATIONS_RELOAD_DOMAIN", { count: 1 }))
                .targetEvent(ev)
                .ok($filter("translate")("RELOAD"))
                .cancel($filter("translate")("CANCEL"));

            $mdDialog
                .show(confirm)
                .then(onConfirm, function () { });

            function onConfirm() {
                $rootScope.spinner.show();
                $http
                    .post(`~/api/v1/settings/sysadmin/reload-domain/${vm.theDomainName}`)
                    .then(successHandling.report, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function clearSearchTerm() {
            vm.userSearch = "";
        }

        vm.queryUsers = function (searchText) {
            if (searchText.length > 0) {
                return $http
                    .get(`~/api/v1/settings/sysadmin/list-usernames/${vm.theDomainName}?usersToReturn=50&search=${searchText}`)
                    .then(function (result) {
                        return result.data.usernames;
                    }, errorHandling.report)
                    .catch(function () { return []; });
            }

            return $q.when([]);
        };

        // Modals
        function openAdvancedXMLBody(ev, isMobile) {
            $mdDialog.show({
                locals: {
                    domain: vm.theDomainName,
                    isMobile: isMobile,
                    text: isMobile ? vm.autoDiscoverSettings.mobileXML : vm.autoDiscoverSettings.desktopXML,
                    settings: vm.autoDiscoverSettings.services
                },
                controller: "editAutodiscoverXml",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/manage/domains/modals/edit-autodiscover-xml.dlg.html",
                targetEvent: ev,
                clickOutsideToClose: false
            })
                .then(function (modalSuccess) {
                    vm.pageForm.$setDirty();
                    if (isMobile)
                        vm.autoDiscoverSettings.mobileXML = modalSuccess;
                    else
                        vm.autoDiscoverSettings.desktopXML = modalSuccess;
                }, function (modalCancel) {
                    // Cancel
                });
        }

        function openAutoDiscoverModal(ev, type, enabled) {
            if (!enabled)
                return;
            $mdDialog.show({
                locals: {
                    type: type,
                    domain: vm.theDomainName,
                    hostname: vm.settings.hostname,
                    settings: vm.autoDiscoverSettings.services[type]
                },
                controller: "editAutoDiscoverSettingsController",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/manage/domains/modals/edit-autodiscover-settings.dlg.html",
                targetEvent: ev,
                clickOutsideToClose: false
            })
                .then(function (modalSuccess) {
                    vm.pageForm.$setDirty();
                    vm.autoDiscoverSettings.services[type] = modalSuccess;
                }, function (modalCancel) {
                    // Cancel
                });
        }

        function reindexDomainArchive() {
            var params = JSON.stringify({ messageArchiveName: vm.theDomainName });
            var confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_REINDEX"))
                .ok($translate.instant("REINDEX"))
                .noWarn(true);

            $mdDialog
                .show(confirm)
                .then(onConfirm);
            function onConfirm() {

                $rootScope.spinner.show();
                $http
                    .post("~/api/v1/settings/sysadmin/start-domain-archive-reindex", params)
                    .then(successHandling.report, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function openArchiveRuleEditModal(ev) {

            return $mdDialog.show({
                locals: { rule: vm.archiveRule },
                controller: "systemMessageArchivingRuleModalController",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/shared/modals/archive-rule.dlg.html",
                targetEvent: ev
            });
        }

        
        function editAchiveRule(ev) {
            openArchiveRuleEditModal(ev)
                .then(function (result) {
                    successHandling.report("SUCCESS");
                    activate();
                }, function () { });
        }
        function disableArchivingRule(ev) {
            $http.get("~/api/v1/settings/sysadmin/domain-archive-rule/" + vm.archiveRule.domain)
                .then(function (success) {
                    $mdDialog
                        .show({
                            locals: {
                                archivePath: success.data.rule.archivePath,
                                archiveSize: success.data.rule.archiveSize
                            },
                            controller: "archiveConfirmDisableDialogController",
                            controllerAs: "ctrl",
                            templateUrl: "app/sysadmin/shared/modals/archive-confirm-disable.dlg.html",
                            targetEvent: ev
                        })
                        .then(onConfirmed, function () { });

                }, function () { });

            function onConfirmed(modalData) {
                var params = {};
                var rule = {
                    archivePath: vm.archiveRule.archivePath,
                    domain: vm.archiveRule.domain,
                    incoming: vm.archiveRule.type == 1 || vm.archiveRule.type == 2,
                    outgoing: vm.archiveRule.type == 1 || vm.archiveRule.type == 3,
                    disabled: true,
                    monthsToKeep: vm.archiveRule.yearsToKeep * 12
                };
                /**
                 * Currently we are not allowing deletions of existing archives
                 *	disabling an archive is synonymous with a detach; 
                 *	this may change especially at the domain level as we are not providing a way to remove this configuration
                 *	
                if (modalData.deleteData) {
                    params.toRemove = [rule.domain];
                } else {
                    params.rules = [rule];
                }
                 * */
                params.rules = [rule];
                $rootScope.spinner.show();
                $http
                    .post("~/api/v1/settings/sysadmin/domain-archive-rules", JSON.stringify(params))
                    .then(onDeleteSuccess, errorHandling.report)
                    .finally($rootScope.spinner.hide);

                function onDeleteSuccess() {
                    successHandling.report("SUCCESS");
                    activate();
                }
            }

        }
    }

})();
