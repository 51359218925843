(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("emailAttachmentsController", emailAttachmentsController);

	emailAttachmentsController.$inject = ["$scope", "$mdDialog", "$http", "errorHandling", "message", "authStorage", "localeInfoService","userDataService","$rootScope", "$translate"];

	function emailAttachmentsController($scope, $mdDialog, $http, errorHandling, message, authStorage, localeInfoService,userDataService, $rootScope,$translate) {
		$scope.controller = this;
		$scope.attachments = message.attachments || [];
		$scope.allAttachmentFile = null;
		$scope.showOptions = false;

		activate();
		//////////////////////////

		function activate() {
			$rootScope.spinner.show();
			userDataService.init()

				.then(function () {
					$scope.features = userDataService.user.settings.features;
					$scope.enableOnlyOffice = $scope.features.enableOnlyOffice;
					$scope.enablewopi = $scope.features.enableWOPI;
					$scope.wopiurl = $scope.features.wopiURL;
					$rootScope.spinner.hide();
				});
		}
		
		
		for (let i = 0; i < $scope.attachments.length; i++) {
			const item = $scope.attachments[i];
			if (item.allAttachments) {
				$scope.allAttachmentFile = item;
				break;
			}
		}

		$scope.close = function () {
			$mdDialog.cancel();
		};


		async function getWopiToken() {
			return $http.post("~/wopi/GetWOPIAccessToken")
				.then(response => {
					if (response.data && response.data.token) {
						// Only return the token string
						return response.data.token;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error(error);
					return "Unknown";
				});
		}
		
		function findWhichAttachmentThisIsInTheList(attachment) {
			// Collabora online requires unique file ids or it will open the same document over and over with a different name
			// This is a problem if you have multiple attachments. OnlyOffice doesnt seem to care. 
			// This function just checks to see where in the attachment list the selected attachment is then appends that to the end of the file id.
			
			for (let i = 0; i < $scope.attachments.length; i++) {
				const item = $scope.attachments[i];
				if (item.allAttachments) {
					continue;
				}
				if (item.filename === attachment.filename) {
					return i;
				}
			}
			
		}

		// Variable to store the reference to the currently opened window
		let collaboraWindow = null;

		$scope.wopiEditCollabora = async function (item) {
			// Check if a window is already open
			if (collaboraWindow && !collaboraWindow.closed) {
				errorHandling.report($translate.instant("COLLABORA_WINDOW_ALREADY_OPEN"));
				return;
			}

			let myData = item.link.split("/");
			for (const segment of myData) {
				if (segment.includes("download?data=")) {
					myData = "---" + findWhichAttachmentThisIsInTheList(item) + "---" + segment.split("download?data=")[1];
					break;
				}
			}
			try {
				const wopiToken = await getWopiToken();
				const url = await getDiscoveryUrl(2, item.filename, "view");
				if (!url || url === "Unknown") {
					errorHandling.report($translate.instant("THIS_ITEM_CANNOT_BE_PREVIEWED"));
					return;
				}
				if (wopiToken === "Unknown") {
					console.error("Failed to retrieve WOPI access token");
					return;
				}
				const wopiSrcUrl = new URL(`${window.location.origin}/wopi/files`);
				wopiSrcUrl.searchParams.set('file_id', myData);  // Set file_id as a query parameter
				wopiSrcUrl.searchParams.set('access_token', wopiToken);  // Set access_token as a query parameter

				const urlToOpen = `${url}?WOPISrc=${encodeURIComponent(wopiSrcUrl.toString())}`;

				// Open the new window and store its reference
				collaboraWindow = window.open(urlToOpen, "_blank");

				// Add an event listener to detect when the window is closed
				const checkWindowClosed = setInterval(() => {
					if (collaboraWindow.closed) {
						collaboraWindow = null;
						clearInterval(checkWindowClosed);
					}
				}, 1000);

			} catch (error) {
				console.error("Error in wopiEditCollabora:", error);
			}
		};
		async function getDiscoveryUrl(editorType, filename, edit) {
			return $http.post("~/wopi/getdiscoveryurl/" + filename, { editorType, filename, edit })
				.then(response => {
					if (response.data && response.data.discoveryUrl) {
						// Only return the discovery URL
						return response.data.discoveryUrl;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error(error);
					return "Unknown";
				});
		}

		$scope.wopiEditOnlyOffice = async function (item) {
			try {
				// Validate item object
				if (!item || !item.link || !item.filename) {
					console.error("Invalid item passed to wopiEditOnlyOffice");
					return;
				}

				// Extract the file_id from the item link
				let myData = null;
				const linkSegments = item.link.split("/");
				for (const segment of linkSegments) {
					if (segment.includes("download?data=")) {
						myData = segment.split("download?data=")[1];
						break;
					}
				}

				if (!myData) {
					console.error("Failed to extract file_id from item link");
					return;
				}

				const url = await getDiscoveryUrl(1, item.filename, "view");
				if (!url || url === "Unknown") {
					errorHandling.report($translate.instant("THIS_ITEM_CANNOT_BE_PREVIEWED"));
					return;
				}
				const wopiToken = await getWopiToken();

				if (!url) {
					console.error("Failed to retrieve discovery URL");
					return;
				}
				if (!wopiToken) {
					console.error("Failed to retrieve WOPI token");
					return;
				}

				// URL-encode the file_id
				const encodedFileId = encodeURIComponent(myData);

				// Construct the wopiSrc URL with file_id in the path
				const wopiSrcUrl = `${window.location.origin}/wopi/files/${encodedFileId}`;

				console.log("Constructed WOPISrc URL:", wopiSrcUrl);

				// Construct the action URL
				const actionUrl = `${url}?WOPISrc=${encodeURIComponent(wopiSrcUrl)}`;
				console.log("Constructed Action URL:", actionUrl);

				// Create and submit the form
				const form = document.createElement('form');
				form.method = 'POST';
				form.action = actionUrl;
				form.target = '_blank'; // Opens in a new tab

				// Add the access token as a hidden input
				const accessTokenInput = document.createElement('input');
				accessTokenInput.type = 'hidden';
				accessTokenInput.name = 'access_token';
				accessTokenInput.value = wopiToken;
				form.appendChild(accessTokenInput);

				// Append, submit, and remove the form
				document.body.appendChild(form);
				form.submit();
				document.body.removeChild(form);

				console.log("WOPI Edit request submitted successfully");
			} catch (error) {
				console.error("Error in wopiEditOnlyOffice:", error.message);
			}
		};

	}
})();