angular
	.module("smartermail")
	.controller("onlyOfficeController", onlyOfficeController);

function onlyOfficeController($timeout, $scope, $translate, $q, $mdDialog, $http, meetingWorkspaces, coreDataCalendar, coreDataFileStorage, errorHandling,
							  userTimeService, coreData, coreDataSettings, claimsService, themesService, localeInfoService, type, $rootScope,userDataService) {
	var vm = this;

	vm.extension;

	vm.type = type;
	vm.allFolders = [];

	// Available document types
	vm.availableDocumentTypes = [
		{ id: 0, desc: $translate.instant("DOCUMENT") },
		{ id: 1, desc: $translate.instant("DOCUMENT_PRESENTATION") },
		{ id: 2, desc: $translate.instant("DOCUMENT_SPREADSHEET") }
	];
//vars
	vm.details = {
		id: null,
		title: null,
		availableDocumentTypes: vm.type,
		selectedFolder: null
	};

	vm.isValid = true;
	vm.enableOnlyOffice = false;
	vm.enablewopi = false;

	// Functions
	vm.cancel = cancel;
	vm.save = save;
	vm.getFolders = getFolders;
	vm.wopiEditCollabora = wopiEditCollabora;
	vm.wopiEditOnlyOffice = wopiEditOnlyOffice;
	vm.setDefaultEditor = setDefaultEditor;

	activate();
	
	function activate() {
		$rootScope.spinner.show();
		getFolders();
		userDataService.init()

			.then(function () {
				vm.features = userDataService.user.settings.features;
				vm.enableOnlyOffice = vm.features.enableOnlyOffice;
				vm.enablewopi = vm.features.enableWOPI;
				setDefaultEditor();
				$rootScope.spinner.hide();
			});
	}

	function setDefaultEditor() {
		if (vm.enableOnlyOffice && vm.enablewopi) {
			vm.details.selectedEditor = 'onlyoffice';
		} else if (vm.enableOnlyOffice) {
			vm.details.selectedEditor = 'onlyoffice';
		} else if (vm.enablewopi) {
			vm.details.selectedEditor = 'collabora';
		}
	};


		function getFolders() {
			$http.get("~/api/v1/filestorage/folders")
				.then(function (response) {
					vm.allFolders = getAllFolders(response.data.folder.subFolders);
				})
				.catch(function (error) {
					console.error('Error fetching folders:', error);
					errorHandling.error('Unable to retrieve folders. Please try again later.');
				});
		}

		function getAllFolders(folders) {
			var allFolders = [];
			folders.forEach(function (folder) {
				if (folder.name !== "ONLINE_MEETING_FILES" && folder.name !== "") {
					allFolders.push(folder);
					if (folder.subFolders && folder.subFolders.length > 0) {
						var subFolders = getAllFolders(folder.subFolders);
						allFolders = allFolders.concat(subFolders);
					}
				}
			});
			return allFolders;
		}

		async function getWopiToken() {
			return $http.post("~/wopi/GetWOPIAccessToken")
				.then(response => {
					if (response.data && response.data.token) {
						return response.data.token;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error(error);
					return "Unknown";
				});
		}

		async function getDiscoveryUrl(editorType, filename, edit) {
			return $http.post("~/wopi/getdiscoveryurl/" + filename, {editorType, filename, edit})
				.then(response => {
					if (response.data && response.data.discoveryUrl) {
						return response.data.discoveryUrl;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error(error);
					return "Unknown";
				});
		}

		async function wopiEditCollabora(args) {
			var file = args.card;
			try {
				const wopiToken = await getWopiToken();
				const discoveryUrl = await getDiscoveryUrl(2, file.fileName, "edit");
				if (!wopiToken || wopiToken === "Unknown") {
					console.error("Failed to retrieve WOPI access token");
					return;
				}
				const wopiSrcUrl = new URL(`${window.location.origin}/wopi/files/${encodeURIComponent(file.id)}`);
				const urlToOpen = `${discoveryUrl}?WOPISrc=${encodeURIComponent(wopiSrcUrl.toString())}&access_token=${encodeURIComponent(wopiToken)}`;
				window.open(urlToOpen, "_blank");
			} catch (error) {
				console.error("Error in wopiEditCollabora:", error);
			}
		}

		async function wopiEditOnlyOffice(args) {
			try {
				if (!args || !args.card || !args.card.id) {
					console.error("Invalid arguments passed to wopiEditOnlyOffice");
					return;
				}
				const file = args.card;
				const url = await getDiscoveryUrl(1, file.fileName, "edit");
				const wopiToken = await getWopiToken();
				if (!wopiToken) {
					console.error("Failed to retrieve WOPI access token");
					return;
				}
				const wopiSrcUrl = `${window.location.origin}/wopi/files/${encodeURIComponent(file.id)}`;
				const actionUrl = `${url}?wopisrc=${encodeURIComponent(wopiSrcUrl)}`;
				const form = document.createElement('form');
				form.method = 'POST';
				form.action = actionUrl;
				form.target = '_blank';

				const accessTokenInput = document.createElement('input');
				accessTokenInput.type = 'hidden';
				accessTokenInput.name = 'access_token';
				accessTokenInput.value = wopiToken;
				form.appendChild(accessTokenInput);
				document.body.appendChild(form);
				form.submit();
				document.body.removeChild(form);
				console.log("WOPI Edit request submitted successfully");
			} catch (error) {
				console.error("Error in wopiEditOnlyOffice:", error.message);
			}
		}

		function save() {
			var language = localeInfoService.language;
			vm.isValid = true;

			if (!vm.details.title) {
				vm.isValid = false;
			}

			if (vm.isValid) {
				var documentType = vm.details.availableDocumentTypes;
				if (documentType == "0") {
					vm.extension = ".docx";
				} else if (documentType == "1") {
					vm.extension = ".pptx";
				} else if (documentType == "2") {
					vm.extension = ".xlsx";
				}

				var folder = vm.allFolders.find(function (f) {
					return f.path === vm.details.selectedFolder;
				});
				var document = {
					language: language,
					filetype: documentType,
					folder: folder ? vm.details.selectedFolder : '',
					fn: vm.details.title
				};

				$http.post("~/api/v1/filestorage/create-document-from-template", document, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
					.then(function (response) {
						const existingcallback = coreDataFileStorage.addFilesCallback;
						coreDataFileStorage.addFilesCallback = (files) => {
							if (typeof existingcallback === "function") {
								existingcallback(files);
							}

							if (files[0].fileName == vm.details.title + vm.extension) {
								coreDataFileStorage.addFilesCallback = existingcallback;

								if (vm.details.selectedEditor === "onlyoffice") {
									wopiEditOnlyOffice({card: files[0]});
								} else if (vm.details.selectedEditor === "collabora") {
									wopiEditCollabora({card: files[0]});
								}
								$mdDialog.hide(true);
							}
						};
					}, function (error) {
						console.error('Error saving document:', error);
						errorHandling.error(error);
						$mdDialog.hide(false);
					});
			}
		}

		function cancel() {
			$mdDialog.hide();
		}
	}
